import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.registrarIconosSVG();
  }

  registrarIconosSVG(): void {
    const basePath = `${environment.ASSETS_URL}svg/`;

    this.matIconRegistry.addSvgIcon(
      'chile',
      this.registrarSafePath(`${basePath}chile.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'argentina',
      this.registrarSafePath(`${basePath}argentina.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'bolivia',
      this.registrarSafePath(`${basePath}bolivia.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'uruguay',
      this.registrarSafePath(`${basePath}uruguay.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'paraguay',
      this.registrarSafePath(`${basePath}paraguay.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'remove',
      this.registrarSafePath(`${basePath}remove.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'inicio',
      this.registrarSafePath(`${basePath}inicio.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'proveedores',
      this.registrarSafePath(`${basePath}proveedores.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'perfil',
      this.registrarSafePath(`${basePath}perfil.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'pagos',
      this.registrarSafePath(`${basePath}pagos.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'ordenes-de-compra',
      this.registrarSafePath(`${basePath}ordenes-de-compra.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'logs-de-actualizacion',
      this.registrarSafePath(`${basePath}logs-de-actualizacion.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'logout',
      this.registrarSafePath(`${basePath}logout.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'legajos',
      this.registrarSafePath(`${basePath}legajos.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'gestion-de-usuarios',
      this.registrarSafePath(`${basePath}gestion-de-usuarios.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'facturas',
      this.registrarSafePath(`${basePath}facturas.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'entregas',
      this.registrarSafePath(`${basePath}entregas.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'cotizaciones',
      this.registrarSafePath(`${basePath}cotizaciones.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'consultas',
      this.registrarSafePath(`${basePath}consultas.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'configuracion',
      this.registrarSafePath(`${basePath}configuracion.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'comparativa-precios',
      this.registrarSafePath(`${basePath}comparativa-precios.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-left',
      this.registrarSafePath(`${basePath}chevron-left.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-right',
      this.registrarSafePath(`${basePath}chevron-right.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'mas',
      this.registrarSafePath(`${basePath}mas.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.registrarSafePath(`${basePath}edit.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'mail',
      this.registrarSafePath(`${basePath}mail.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'phone',
      this.registrarSafePath(`${basePath}call.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'download',
      this.registrarSafePath(`${basePath}download.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'check-circle',
      this.registrarSafePath(`${basePath}check-circle.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'close',
      this.registrarSafePath(`${basePath}close.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'ordemaniento',
      this.registrarSafePath(`${basePath}ordemaniento.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'filtro',
      this.registrarSafePath(`${basePath}filtro.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-pag-select',
      this.registrarSafePath(`${basePath}arrow-pag-select.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'empresa',
      this.registrarSafePath(`${basePath}empresa.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'location',
      this.registrarSafePath(`${basePath}location.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'phone',
      this.registrarSafePath(`${basePath}phone.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'ver',
      this.registrarSafePath(`${basePath}ver.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'chat',
      this.registrarSafePath(`${basePath}chat.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'chat2',
      this.registrarSafePath(`${basePath}chat2.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'error',
      this.registrarSafePath(`${basePath}error.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'folder',
      this.registrarSafePath(`${basePath}folder.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'no_documentos',
      this.registrarSafePath(`${basePath}no_documentos.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'estado-activo',
      this.registrarSafePath(`${basePath}estado-activo.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'estado-inactivo',
      this.registrarSafePath(`${basePath}estado-inactivo.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'wsp',
      this.registrarSafePath(`${basePath}wsp.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'jabber',
      this.registrarSafePath(`${basePath}jabber.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'mail-fill',
      this.registrarSafePath(`${basePath}mail-fill.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'check-seleccionado',
      this.registrarSafePath(`${basePath}check-seleccionado.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'schedule',
      this.registrarSafePath(`${basePath}schedule.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'tips',
      this.registrarSafePath(`${basePath}tips.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'calendario',
      this.registrarSafePath(`${basePath}calendario.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'columnas',
      this.registrarSafePath(`${basePath}column.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'expand-more',
      this.registrarSafePath(`${basePath}expand-more.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'sugerencia',
      this.registrarSafePath(`${basePath}suggestion.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'handshake',
      this.registrarSafePath(`${basePath}handshake.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'arrow_forward',
      this.registrarSafePath(`${basePath}arrow_forward.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'new_releases',
      this.registrarSafePath(`${basePath}new_releases.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'enviar',
      this.registrarSafePath(`${basePath}send.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.registrarSafePath(`${basePath}delete.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'verificado',
      this.registrarSafePath(`${basePath}verificado.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'upload',
      this.registrarSafePath(`${basePath}upload.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'adjuntar',
      this.registrarSafePath(`${basePath}adjuntar.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'expand-less',
      this.registrarSafePath(`${basePath}expand-less.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'cancel',
      this.registrarSafePath(`${basePath}cancel.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'error2',
      this.registrarSafePath(`${basePath}error2.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'schedule-fill',
      this.registrarSafePath(`${basePath}schedule-fill.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'call-fill',
      this.registrarSafePath(`${basePath}call-fill.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'user-avatar',
      this.registrarSafePath(`${basePath}user-avatar.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'search',
      this.registrarSafePath(`${basePath}search.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'play',
      this.registrarSafePath(`${basePath}play.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'note',
      this.registrarSafePath(`${basePath}note.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'trending_up',
      this.registrarSafePath(`${basePath}trending_up.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'add-comment',
      this.registrarSafePath(`${basePath}add-comment.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'sync_alt',
      this.registrarSafePath(`${basePath}sync_alt.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'pending_actions',
      this.registrarSafePath(`${basePath}pending_actions.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'ordenamiento-asd',
      this.registrarSafePath(`${basePath}ordenamiento-mayor.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'ordenamiento-desc',
      this.registrarSafePath(`${basePath}ordenamiento-menor.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'warning',
      this.registrarSafePath(`${basePath}warning.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'contact_mail',
      this.registrarSafePath(`${basePath}contact_mail.svg`)
    );
  }

  registrarSafePath(path: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(path);
  }
}
