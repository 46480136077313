import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { IUser } from '@interfaces/user.interfaces';
import { Iemail } from '@interfaces/email.interfaces';
import { IToken, IResetPassword } from '@interfaces/password.interfaces';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { IPermissionsObject } from '@interfaces/permissions.interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user = new BehaviorSubject<IUser['data'] | null>(null);
  public userLoggedIn$ = this._user.asObservable();

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService
  ) {
    this.loadUser();
  }

  //Solicitud cambio de contraseña
  forgotPassword(email: string): Observable<Iemail> {
    return this.apiService.post<Iemail>('/password/email', { email });
  }

  //Validacion token
  validateToken(
    email: string | null,
    token: string | null
  ): Observable<IToken> {
    return this.apiService.post<IToken>('/password/validate', { email, token });
  }

  //Cambio de contraseña
  resetPassword(
    email: string,
    token: string,
    password: string,
    password_confirmation: string
  ): Observable<IResetPassword> {
    return this.apiService.post<IResetPassword>('/password/reset', {
      email,
      token,
      password,
      password_confirmation,
    });
  }

  login(credentials: {
    username: string;
    password: string;
  }): Observable<IUser> {
    return this.apiService.post<IUser>('/auth/login', credentials).pipe(
      map((user) => {
        this.setTokenAndUserData(user.data.token, user.data);
        this.setPermissions(user.data.permissions);
        this._user.next(user.data);
        return user;
      })
    );
  }

  loadUser(): void {
    const token = this.localStorageService.getToken();
    const user = this.localStorageService.getUserData();

    if (token && user) {
      this.apiService.setHeader('Authorization', `Bearer ${token}`);
      this._user.next(user);
    }
  }

  logout() {
    return this.apiService.post('/auth/logout').pipe(
      map(() => {
        this.apiService.deleteHeader('Authorization');
        this._user.next(null);
        localStorage.clear();
      })
    );
  }

  logoutLocal() {
    this.apiService.deleteHeader('Authorization');
    this._user.next(null);
    localStorage.clear();
  }

  public get userIsLoggedIn(): boolean {
    return this._user.getValue() !== null;
  }

  private setTokenAndUserData(token: string, user: IUser['data']): void {
    this.localStorageService.setToken(token);
    this.localStorageService.setUserData(user);
    this.apiService.setHeader('Authorization', `Bearer ${token}`);
  }

  private setPermissions(data: IPermissionsObject[]) {
    const arrayPermissions = data.map((x) => {
      return x.name;
    });
    this.localStorageService.setPermissions(arrayPermissions);
  }
}
