import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenExpiredInterceptorInterceptor } from '@interceptors/logout-interceptor/token-expired-interceptor.interceptor';
import { RedirectInterceptor } from '@interceptors/redirect/redirect.interceptor';
import { FormsModule } from '@angular/forms';
import { environment } from '@environment/environment';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import localeEsAr from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';

const config: SocketIoConfig = { url: environment.NODE_JS_URL, options: {} };
registerLocaleData(localeEsAr, 'es-Ar');
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    FormsModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenExpiredInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RedirectInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-Ar' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
