import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PasswordService } from '@services/password/password.service';
import { ToastService } from '@services/toast/toast.service';
import { ConstToastType } from '@interfaces/toast.interfaces';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class QueryTokenEmailGuard implements CanActivate {
  constructor(
    private passwordService: PasswordService,
    private router: Router,
    private toastService: ToastService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const token = route.queryParams['token'];
    const email = route.queryParams['email'];
    if (token && email) {
      return this.passwordService.validateToken(email, token).pipe(
        map((res) => {
          if (res.data) {
            return true;
          }
          this.router.navigate(['/login/forgot-password'], {
            state: { val: true },
          });
          return false;
        })
      );
    } else {
      this.router.navigate(['/login/forgot-password'], {
        state: { val: true },
      });
      return of(false);
    }
  }
}
