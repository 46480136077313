<div class="toast-container" [ngClass]="toastType">
  <mat-icon class="icon-type">{{ icon }}</mat-icon>
  <div>
    <span>{{ message }}</span>
    <ul *ngIf="domains && domains.length">
      <li *ngFor="let domain of domains">{{ domain }}</li>
    </ul>
    <a target="_blank" [href]="url" *ngIf="url?.length">{{ url }}</a>
  </div>
  <mat-icon class="icon-close" (click)="close()">close</mat-icon>
</div>
