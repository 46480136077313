import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appImageSrc]'
})
export class ImageSrcDirective implements OnInit {

  @Input() appImageSrc: string | undefined;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    const basePath = 'assets/';
    const imagePath = `${basePath}${this.appImageSrc}`;
    this.elementRef.nativeElement.src = imagePath;
  }

}
