import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable()
export class TokenExpiredInterceptorInterceptor implements HttpInterceptor {
  show = false;
  message!: string;
  type: string = 'default';

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.toString().includes('auth/logout')) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.error?.code === 401 &&
          error.error?.status === 'token_expired'
        ) {
          this.openSnackBar();
          this.authService.logoutLocal();
          this.router.navigate(['/login']);
          throw new Error('token_expired');
        }
        return next.handle(request);
      })
    );
  }

  openSnackBar() {
    const config = {
      duration: 3000,
      horizontalPosition: 'right' as MatSnackBarHorizontalPosition,
      verticalPosition: 'top' as MatSnackBarVerticalPosition,
      panelClass: 'warning',
    };
    this.snackBar.open('Su token ha expirado', 'Cerrar', config);
  }
}
